import React, {useRef, useEffect} from 'react'
import clsx from 'clsx'

// Animates hiding/showing content.
// Expands to height of content using transition when expanded.
// Shrinks to zero height using transition when collapsed.
const Collapse = (props) => {
  // this can be expanded and collapsed at various break points.
  // If expanded is false and expandedLg is true it will
  // appear expanded for large screens but collapsed for smaller screens
  const {expanded, expandedSm, expandedMd, expandedLg, expandedXl, expandedXxl, children, className, ...other} = props
  // ref.current will be set to parent div element after first render
  const ref = useRef()
  // innerRef.current will be set to inner div element after first render
  const innerRef = useRef()

  // this runs after the component is mounted (first render)
  useEffect(() => {
    const measure = () => {
      // if the window resizes the content may change height (e.g. if the window is narrower lines of text may wrap).
      // each time the window resizes we need measure the content again and update the containers height.
      ref.current.style.setProperty('--content-height', `${innerRef.current.offsetHeight}px`)
    }
    window.addEventListener('resize', measure)
    measure()

    // this runs before the component is unmounted
    return () => {
      window.removeEventListener('resize', measure)
    }
  }, [children])

  return (
    <div
      ref={ref}
      className={
        clsx(
          className,
          'faj-collapse',
          expanded && 'faj-collapse--expanded',
          expandedSm && 'faj-collapse--expanded-sm',
          expandedMd && 'faj-collapse--expanded-md',
          expandedLg && 'faj-collapse--expanded-lg',
          expandedXl && 'faj-collapse--expanded-xl',
          expandedXxl && 'faj-collapse--expanded-xxl',
        )
      }
      {...other}
    >
      <div ref={innerRef}>
        {children}
      </div>
    </div>
  )
}

export default Collapse
