import React from 'react'
import Meta from '../components/Meta'

const Accessibility = () => {
  return (
    <div className="container-lg max-width-xl pt-4 pt-lg-5 pb-5 mb-lg-5 px-4 px-lg-5">
      <Meta
        title="Accessibility Statement"
        description="Accessibility Statement"
        type="article"
      />

      <h1>
        Accessibility Statement for Kimi Mahi Mai - Find a Job
      </h1>
      <p>
        This accessibility statement is for the website at {' '}
        <a href="https://findajob.msd.govt.nz">findajob.msd.govt.nz</a>.
      </p>
      <p>‘Accessibility’ means designing things that disabled people can perceive, use, and understand.</p>
      <h2>How we support accessibility</h2>
      <p>We support accessibility by:</p>
      <ul className="ak-ul" data-indent-level="1">
        <li><p>providing alternate formats</p></li>
        <li><p>including accessibility in our policies</p></li>
        <li><p>training our staff</p></li>
        <li><p>checking our websites for issues, keeping track of issues, and fixing them</p></li>
        <li><p>responding positively to complaints</p></li>
        <li>
          <p>
            delivering on the {' '}
            <a href="https://www.msd.govt.nz/about-msd-and-our-work/work-programmes/accessibility/index.html">
              Accessibility Charter
            </a>
          </p>
        </li>
        <li>
          <p>
            working to meet the {' '}
            {/* eslint-disable-next-line max-len */}
            <a href="https://www.digital.govt.nz/standards-and-guidance/nz-government-web-standards/web-accessibility-standard-1-1/">
              NZ Government Web Accessibility Standard
            </a>.
          </p>
        </li>
      </ul>
      <h3>Accessibility Charter</h3>
      <p>
        MSD is a signatory to the {' '}
        <a href="https://www.msd.govt.nz/about-msd-and-our-work/work-programmes/accessibility/index.html">
          Accessibility Charter
        </a>. The Accessibility Charter is a commitment to providing accessible information to the public. As
        part of this commitment, we developed a 5-year programme of work. We have a dedicated team to carry out this
        work.
      </p>
      <h2>Alternate formats</h2>
      <p>
        Contact us at <a href="mailto:kimimahimai@msd.govt.nz">kimimahimai@msd.govt.nz</a> if you need information
        in alternate formats, such as:
      </p>
      <ul className="ak-ul" data-indent-level="1">
        <li><p>Easy Read</p></li>
        <li><p>New Zealand Sign Language (NZSL)</p></li>
        <li><p>Braille</p></li>
        <li><p>audio</p></li>
        <li><p>large print</p></li>
        <li><p>plain text.</p></li>
      </ul>
      <p>In your message, include:</p>
      <ul className="ak-ul" data-indent-level="1">
        <li><p>the web address (URL) of the information you need</p></li>
        <li><p>the format you need — for example, Braille.</p></li>
      </ul>
      <h2>Feedback</h2>
      <p>
        Contact us at <a href="mailto:kimimahimai@msd.govt.nz">kimimahimai@msd.govt.nz</a> if you find
        accessibility issues on this website. We aim to respond to feedback within 3 business days.
      </p>
      <h2>Formal accessibility complaints</h2>
      <p>
        To make a formal complaint, follow the {' '}
        <a href="https://www.msd.govt.nz/about-msd-and-our-work/contact-us/complaints/index.html">
          complaints information on the MSD website
        </a>.
      </p>
      <p>
        If you make a complaint and you&apos;re not satisfied with the response, you can learn about your options
        from:
      </p>
      <ul className="ak-ul" data-indent-level="1">
        <li><p>the <a href="https://www.hrc.co.nz/">Human Rights Commission</a></p></li>
        <li><p>the <a href="https://www.ombudsman.parliament.nz/">Ombudsman</a>.</p></li>
      </ul>
      <h2>Conformance status</h2>
      <p>
        The {' '}
        <a href="https://www.w3.org/WAI/standards-guidelines/wcag/">
          Web Content Accessibility Guidelines (WCAG)
        </a> defines requirements for web accessibility. This website aims to conform to WCAG 2.1 Level AA. We
        are progressively working to become fully conformant to WCAG 2.1 Level AA.
      </p>
      <p>
        Accessibility checks against the {' '}
        {/* eslint-disable-next-line max-len */}
        <a href="https://www.digital.govt.nz/standards-and-guidance/nz-government-web-standards/web-usability-standard-1-3/">
          New Zealand Government Web Standards
        </a> were done during the development of this website. An Accessibility
        Audit will be completed post-website launch and the results of that Audit will be provided on this page.
      </p>
      <h3>Content that is not accessible</h3>
      <p>
        There are no known accessibility issues on the website. Contact us
        at <a href="mailto:kimimahimai@msd.govt.nz">kimimahimai@msd.govt.nz</a> if you need any of the following
        information in an alternate format.
      </p>
      <h2>Compatibility with browsers and assistive technologies</h2>
      <p>This website should work with common browsers and assistive technologies, such as:</p>
      <ul className="ak-ul" data-indent-level="1">
        <li><p>Edge and Chrome with JAWS on Windows</p></li>
        <li><p>Edge and Chrome with NVDA on Windows</p></li>
        <li><p>Edge and Chrome with keyboard-only input on Windows.</p></li>
      </ul>
      <h2>Assessment approach</h2>
      <p>This website’s accessibility was last checked by MSD in January 2023 by:</p>
      <ul className="ak-ul" data-indent-level="1">
        <li><p>evaluating selected pages against WCAG 2.1 Level AA</p></li>
        <li><p>testing selected pages with assistive technologies.</p></li>
      </ul>
      <h2>Approver of this statement</h2>
      <p>
        Digital Accessibility Team<br />Ministry of Social Development<br />Te Manatū Whakahiato Ora<br /> {' '}
        <a href="mailto:accessibility_team@msd.govt.nz">accessibility_team@msd.govt.nz</a>
      </p>
      <h2>Last statement review date</h2>
      <p>10 February 2023</p>
    </div>
  )
}

export default Accessibility
