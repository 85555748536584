import React, {useRef, useEffect} from 'react'
import clsx from 'clsx'

const Loader = (props) => {
  const {className, focused, ...rest} = props

  const ref = useRef()
  useEffect(() => {
    if (focused) {
      ref.current.focus()
    } else {
      ref.current.blur()
    }
  }, [focused])

  return (
    <div className={clsx('loader', className)} aria-label="loading" {...rest} tabIndex={-1} ref={ref}>
      <div className="loader--dot" />
      <div className="loader--dot" />
      <div className="loader--dot" />
    </div>
  )
}

export default Loader

export const PageLoader = (props) => {
  const {className, ...rest} = props

  return (
    <div className={clsx('container-lg py-4 d-flex justify-content-center', className)}>
      <Loader {...rest} />
    </div>
  )
}
