import React, {useEffect, useState} from 'react'
import Error from '../pages/error'
import {getApiErrorData} from '../utils/api'

// This will call resolve to fetch it's dependency (on prop) if it is not present.
// If there is an error fetching the dependency it will display the appropriate error.
// If a loader has been provided it will display it while the dependency is being resolved
const Dependent = (props) => {
  const {on, resolve, loader, children} = props
  const [loading, setLoading] = useState(!on)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!on) {
      const handleResolve = async () => {
        setLoading(true)
        try {
          await resolve()
          setError(null)
        } catch (error) {
          setError(getApiErrorData(error).error)
        } finally {
          setLoading(false)
        }
      }
      handleResolve()
    }
  }, [on, resolve])

  useEffect(() => {
    if (!loading) {
      const h1 = document.querySelector('h1')
      if (h1) {
        h1.tabIndex = -1
        h1.focus()
      }
    }
  }, [loading])

  if (error && error?.type !== 'INVALID') {
    return <Error type={error?.type} />
  }
  return loading ? loader : (children || null)
}

export default Dependent
