import React from 'react'
import TeReo from '../components/TeReo'
import Meta from '../components/Meta'
import Slice, {Hero, SliceHeader, SliceContent} from '../components/Slice'
import heroImageUrl from 'assets/images/contact-us/hero.jpg'
import heroImageXsUrl from 'assets/images/contact-us/hero-mobile.webp'
import heroImageSmUrl from 'assets/images/contact-us/hero-tablet.webp'
import heroImageXlUrl from 'assets/images/contact-us/hero.webp'

const ContactUs = () => {
  return (
    <div className="pb-5 mb-lg-3">
      <Meta
        title="Contact Us"
        description="Find out how to contact Work and Income about Kimi Mahi Mai Find a Job."
        type="article"
      />

      <Slice>
        <Hero
          imageSrc={heroImageUrl}
          imageXsSrc={heroImageXsUrl}
          imageSmSrc={heroImageSmUrl}
          imageXlSrc={heroImageXlUrl}
          title={(
            <>
              <TeReo>Whakapā Mai</TeReo>
              <br />
              Contact Us
            </>
          )}
        />
      </Slice>
      <Slice>
        <SliceContent className="pb-4">
          <p className="fs-3">
            Find out how to contact Work and Income about Kimi Mahi Mai Find a Job.
          </p>
        </SliceContent>
      </Slice>
      <Slice>
        <SliceHeader title="Job Search" />
        <SliceContent>
          <p>Call the Job Search team if you’re looking for a job or have found a job you like on our website.</p>
          <p>
            If you’ve found a job you like, you’ll need to tell us the Job ID. You can find the Job ID at the top of
            the page about the job, below the job title.
          </p>
          <p>
            Monday to Friday, 8am to 6pm.<br />
            Phone: <a href="tel:0800779009">0800 779 009</a><br />
          </p>
        </SliceContent>
      </Slice>
      <Slice variant="alt">
        <SliceHeader title="Deaf, hard of hearing or have a speech impairment" />
        <SliceContent>
          <p>Contact our special team if you are deaf, hard of hearing or have a speech impairment.</p>
          <p>
            {/* eslint-disable-next-line max-len */}
            <a href="https://www.workandincome.govt.nz/about-work-and-income/contact-us/deaf-hard-of-hearing-or-have-a-speech-impairment.html">
              How to contact us if you’re deaf, hard of hearing or have a speech impairment
            </a>
          </p>
        </SliceContent>
      </Slice>
      <Slice>
        <SliceHeader title="Employer Services" />
        <SliceContent>
          <p>If you’re an Employer and would like to advertise your vacancy with us, you can either:</p>
          <ul>
            <li>
              complete the{' '}
              {/* eslint-disable-next-line max-len */}
              <a href="https://www.workandincome.govt.nz/form/workandincome/govt/nz/form.req2?requestType=workandincome-govt-nz-vacancy-form-v1">
                online vacancy form
              </a>
              , or
            </li>
            <li>call the Employer Services team.</li>
          </ul>
          <p>
            Monday to Friday, 8am to 6pm.<br />
            Phone: <a href="tel:0800778008">0800 778 008</a><br />
          </p>
        </SliceContent>
      </Slice>
      <Slice variant="alt">
        <SliceHeader title="Feedback" />
        <SliceContent>
          <p>
            If you have any feedback about this website, you can contact us by email or post. If you want to talk to us
            about applying for a job or listing a vacancy, please call us instead.
          </p>
          <p>
            Email: <a href="mailto:kimimahimai@msd.govt.nz">kimimahimai@msd.govt.nz</a><br />
            Postal address: PO Box 1556, Wellington, 6140<br />
          </p>
        </SliceContent>
      </Slice>
    </div>
  )
}

export default ContactUs
