import React, {createContext, useContext, useEffect, useState} from 'react'
import {index} from './apiActions'

const JobCategoryRepositoryContext = createContext({})
const defaultJobCategories = []

const JobCategoryRepository = (props) => {
  const {children, initialData: {data: jobCategories = defaultJobCategories, ...initialData} = {}} = props
  // gets initial data from props so it can be set for server-side rendering (SSR)
  const [data, setData] = useState({loading: false, ...initialData, jobCategories})

  // pull job categories once when mounted
  useEffect(() => {
    if (jobCategories === defaultJobCategories) {
      index(setData, 'job_categories', 'jobCategories')()
    }
  }, [jobCategories])

  return (
    <JobCategoryRepositoryContext.Provider value={data}>
      {children}
    </JobCategoryRepositoryContext.Provider>
  )
}

export default JobCategoryRepository

export const useJobCategoryRepository = () => {
  return useContext(JobCategoryRepositoryContext)
}
