import React, {useEffect} from 'react'
import Meta from '../../components/Meta'
import {trackMaintenance} from '../../utils/tracking'
import maintenanceImageUrl from 'assets/images/maintenance.svg'

const UnderMaintenance = () => {
  useEffect(trackMaintenance, [])

  return (
    <div className="error-page">
      <Meta title="Under Maintenance" />
      <div className="error-page__image">
        <img src={maintenanceImageUrl} alt="" />
      </div>
      <div className="error-page__text-wrapper">
        <div className="error-page__text-container">
          <h1>Under Maintenance</h1>
          <p>
            Kimi Mahi Mai - Find a Job search is temporarily offline for maintenance.
          </p>
          <p>
            Please come back later to search for jobs.
          </p>
        </div>
      </div>
    </div>
  )
}

export default UnderMaintenance
