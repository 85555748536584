import React, {useContext, useState, useCallback, useMemo, useEffect, useRef} from 'react'
import {Popover} from 'react-tiny-popover'
import clsx from 'clsx'

const Context = React.createContext({})

// Drop down of nested options. Drop down displays as a list of checkboxes. When parent checkbox is checked child
// options are displayed underneath as well as an "all {parent}" option. Parent is deselected when child is selected.
// child options are deselected when "all {parent}" is selected.

// Selected values passed in as an array to the value prop. When items are checked/unchecked onChange will be called
// with an updated array of values.
const MultiLevelSelect = (props) => {
  const {name, id = name, placeHolder = '', children, className, tabIndex = 0, value, onChange, ...other} = props

  const selectedValues = useMemo(() => {
    if (Array.isArray(value)) {
      return value
    }
    if (value === undefined || value === '') {
      return []
    }
    return [value]
  }, [value])

  const setSelectedValues = useCallback((values) => {
    onChange && onChange({target: {name, value: typeof values === 'function' ? values(selectedValues) : values}})
  }, [name, onChange, selectedValues])

  const [open, setOpen] = useState(false)
  const [openedWithKeyboard, setOpenedWithKeyboard] = useState(false)
  const toggleOpen = useCallback(() => window.setTimeout(() => setOpen(prev => !prev), 1), [])
  const close = useCallback(() => setOpen(false), [])

  const buttonRef = useRef()
  const menuRef = useRef()
  const labelRef = useRef()

  const valueLabels = useMemo(() => getChildValueLabels(children), [children])
  const selectionLabel = selectedValues.map(v => valueLabels[v]).filter(l => l).join(', ')

  const handleKeyDown = useCallback((e) => {
    if (e.code === 'Space' && !open) {
      setOpen(true)
      setOpenedWithKeyboard(true)
      e.preventDefault()
    }
  }, [open])

  const voiceOverCollapse = useCallback(() => {
    document.getElementById(id).focus()
    setOpen(false)
  }, [id])

  useEffect(() => {
    if (open) {
      const oldOverflow = document.body.style.overflow
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = oldOverflow
      }
    }
  }, [open])

  const clear = useCallback((e) => {
    e.stopPropagation()
    setSelectedValues([])
    document.getElementById(id).focus()
  }, [setSelectedValues, id])

  useEffect(() => {
    // make this only show when ellipsis:
    // https://stackoverflow.com/questions/7738117/html-text-overflow-ellipsis-detection

    // window is not available in node (used for Server-side rendering) so need to check it is defined before using it
    if (typeof window !== 'undefined') {
      // importing Tooltip from bootstrap runs code that fails with server side rendering (SSR).
      // SSR uses server.js instead of application.js so Tooltip is made available at window.bootstrap.Tooltip in
      // application.js instead of importing it directly here.
      const tooltip = new window.bootstrap.Tooltip(buttonRef.current)
      if (open || labelRef.current.offsetWidth >= labelRef.current.scrollWidth) {
        // don't show tooltip if menu open or ellipsis is not shown
        tooltip.disable()
      }
      return () => {
        tooltip.dispose()
      }
    }
  }, [selectionLabel, open])

  useEffect(() => {
    if (!open) {
      setOpenedWithKeyboard(false)
    }
  }, [open])

  useEffect(() => {
    if (open) {
      const handleKeyDown = (e) => {
        switch (e.code) {
          case 'Escape': {
            // close menu
            buttonRef.current.focus()
            close()
            break
          }
          case 'Enter': {
            // close menu (item will be selected first, this is handles by the option component)
            buttonRef.current.focus()
            window.setTimeout(close, 200)
            break
          }
          case 'ArrowUp':
          case 'ArrowDown': {
            // focus next/prev item when arrow keys pressed
            const delta = e.code === 'ArrowUp' ? -1 : 1
            const options = [...menuRef.current.querySelectorAll('input[type=checkbox]:enabled')]
            const focusedOption = menuRef.current.querySelector('input[type=checkbox]:focus')
            const selectedIndex = focusedOption ? options.indexOf(focusedOption) : Math.min(-delta, 0)
            const newIndex = (selectedIndex + delta + options.length) % options.length
            options[newIndex].focus()
            e.preventDefault()
            break
          }
          case 'Tab': {
            // prevent tab changing focus when menu open (same behaviour as standard select)
            e.preventDefault()
            break
          }
        }
      }
      window.addEventListener('keydown', handleKeyDown)
      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [open, close])

  useEffect(() => {
    if (open) {
      if (openedWithKeyboard) {
        menuRef.current.querySelector('input[type=checkbox]').focus()
      } else {
        document.getElementById(`${id}-popup`).focus()
      }
    }
  }, [open, openedWithKeyboard, id])

  const contextValue = useMemo(() => ({
    id, name, selectedValues, setSelectedValues,
  }), [id, name, selectedValues, setSelectedValues])

  return (
    <Context.Provider value={contextValue}>
      <Popover
        ref={buttonRef}
        isOpen={open}
        onClickOutside={close}
        positions={['bottom', 'top']}
        boundaryElement={windowBoundary}
        align="start"
        content={({childRect}) => (
          <>
            <button type="button" onFocus={voiceOverCollapse} tabIndex={-1} className="visually-hidden">Top</button>
            <fieldset
              id={`${id}-popup`}
              className="top-100 bg-white rounded-1 shadow px-2 py-1 my-1 popup-menu"
              style={{width: childRect.width}}
              tabIndex={-1}
            >
              <ul className="list-unstyled m-0" ref={menuRef}>
                {children}
              </ul>
            </fieldset>
            <button type="button" onFocus={voiceOverCollapse} tabIndex={-1} className="visually-hidden">Bottom</button>
          </>

        )}
      >
        <div className="multiselect">
          <div>
            <div
              role="combobox"
              className={clsx('form-select text-start d-flex align-items-center', className)}
              tabIndex={tabIndex}
              onClick={toggleOpen}
              onKeyDown={handleKeyDown}
              aria-expanded={open}
              aria-controls={open && `${id}-popup`}
              id={id}
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              {...other}
            >
              <div className="position-relative flex-fill">
                <span aria-hidden="true">&nbsp;</span>
                <div id={`${id}-selection`} ref={labelRef} aria-hidden={!selectionLabel}
                     className="position-absolute top-0 bottom-0 start-0 end-0 text-truncate">
                  {selectionLabel || placeHolder}
                </div>
              </div>
            </div>
          </div>
          {!!selectedValues?.length && (
            <button type="button" className="btn btn-close" aria-label="clear" onClick={clear} />
          )}
        </div>
      </Popover>
    </Context.Provider>
  )
}
export default MultiLevelSelect

// provides rectangle for popover
const windowBoundary = {
  getBoundingClientRect: () => new DOMRect(0, 0, window.innerWidth, window.innerHeight),
}

export const Option = (props) => {
  const {id: parentId, name, selectedValues, setSelectedValues} = useContext(Context)
  const {
    value,
    id = `${parentId}_${`${value}`.replaceAll(' ', '-')}`,
    label,
    children,
    className,
    childValues: childValuesProps,
    ...other
  } = props

  const childValues = useMemo(() => childValuesProps || getChildValues(children), [children, childValuesProps])

  const isSameAs = value => other => `${value}` === `${other}`

  const checked = selectedValues.some(isSameAs(value))
  const childChecked = childValues.some(childValue => selectedValues.some(isSameAs(childValue)))
  const setChecked = (checked) => {
    setSelectedValues(prev => [
      // remove the current value and, if the current value is being checked, all the child values
      ...prev.filter(v => !isSameAs(v)(value) && (!checked || !childValues.some(isSameAs(v)))),
      // add clicked value if checked
      ...(checked ? [value] : []),
    ])
  }

  const handleChange = (e) => {
    if (children && childChecked && !checked) {
      // when a child is checked the parent is styled to appear check but is not
      // therefore when an parent with checked children is checked, it and its children need to be unchecked
      setSelectedValues(prev => prev.filter(v => !isSameAs(v)(value) && !childValues.some(isSameAs(v))))
    } else {
      setChecked(e.target.checked)
    }
    e.stopPropagation()
  }

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      e.target.checked = true
      setChecked(e)
    }
  }

  const handleKeyUp = (e) => {
    if (e.code === 'Space') {
      // All checkboxes have child values, but no children
      if (childValues.length > 0 && !children && !checked) {
        // when an all checkbox is checked it will become disabled so we need to focus our parent
        window.setTimeout(() => {
          document.getElementById(parentId).focus()
        }, 1)
      }
    }
  }

  const setChildSelectedValues = useCallback((values) => {
    setSelectedValues((prev) => {
      let newValues = typeof values === 'function' ? values(prev) : values
      const newChecked = newValues.some(isSameAs(value))
      const newChildChecked = childValues.some(childValue => newValues.some(isSameAs(childValue)))

      if (newChecked && newChildChecked) {
        // remove self from selected values when child checked
        newValues = newValues.filter(v => !isSameAs(v)(value))
      } else if (!newChecked && childChecked && !newChildChecked) {
        // check self when all child values unchecked
        newValues = [...newValues, value]
      }
      return newValues
    })
  }, [value, setSelectedValues, childValues, childChecked])

  const contextValue = useMemo(() => ({
    id, name, selectedValues, setSelectedValues: setChildSelectedValues,
  }), [id, name, selectedValues, setChildSelectedValues])

  return (
    <li className={clsx(className, 'py-1 check-option')}>
      <input className={clsx('form-check-input', childChecked && children && 'checked')}
             id={id} type="checkBox" name={`${name}[]`} value={value} checked={checked}
             onChange={handleChange} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} {...other} />
      <label className="form-check-label ps-1" htmlFor={id}>{label}</label>
      {children && (checked || childChecked) && (
        <Context.Provider value={contextValue}>
          <ul className="list-unstyled ps-4">
            <Option value={value} childValues={childValues} label={`All ${label}`} id={`${id}-all`}
                    className="border-bottom mb-1 mx-n1 px-1" disabled={checked} />
            {children}
          </ul>
        </Context.Provider>
      )}
    </li>
  )
}

const getChildValues = (children) => {
  return React.Children.map(children, child => [
    child?.props?.value,
    ...getChildValues(child?.props?.children),
  ].filter(v => v))?.flat() || []
}

const getChildValueLabels = (children) => {
  return React.Children.map(children, child => ({
    ...(child?.props?.value && {[child?.props?.value]: child?.props.label}),
    ...getChildValueLabels(child?.props?.children),
  }))?.reduce((prev, current) => Object.assign(prev, current), {}) || {}
}
