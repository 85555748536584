import React, {createContext, useContext, useState, useCallback, useMemo} from 'react'

const SessionStorageContext = createContext(null)

const getSessionStorageValue = (key) => {
  // window and session storage are not defined in node for server-side rendering (SSR), so return undefined
  if (typeof window === 'undefined') return undefined

  const rawSessionValue = window.sessionStorage.getItem(key)
  return rawSessionValue && JSON.parse(rawSessionValue)
}

// JS does not provide any events for session storage changes. We want to rerender when session storage changes.
// To do this we need also need to store values in react state to trigger a rerender when values change.

// SessionStorageProvider provides shared state for session storage to all of it's descendants.
export const SessionStorageProvider = ({children}) => {
  const [sessionData, setSessionData] = useState({})

  // useCallback returns the same instance of the getSessionValue function each render until sessionData changes.
  const getSessionValue = useCallback((key, defaultValue) => {
    if (!sessionData.hasOwnProperty(key)) {
      // value not in sessionData (react state) so get it from session storage
      // React expects initial render to match server-side render (SSR) and session storage is not available for SSR
      // Therefore we need to update our sessionData state after the initial render which can be done with a timeout
      typeof window !== 'undefined' && window.setTimeout(() => {
        setSessionData(prev => ({...prev, [key]: getSessionStorageValue(key) || defaultValue}))
      }, 1)
    }
    // value is in sessionData (react state) so get it from there
    return sessionData[key] || defaultValue
  }, [sessionData])

  // useCallback returns the same instance of setSessionValue each render
  const setSessionValue = useCallback((key, value) => {
    // set values in sessionData (react state) and session storage
    setSessionData((prev) => {
      const prevValue = !prev.hasOwnProperty(key) ? getSessionStorageValue(key) : prev[key]
      const resolvedValue = typeof value === 'function' ? value(prevValue) : value
      // window and session storage are not available during server-side render (SSR) so only need update the state
      if (typeof window !== 'undefined') window.sessionStorage.setItem(key, JSON.stringify(resolvedValue))
      return {...prev, [key]: resolvedValue}
    })
  }, [])

  // useMemo returns the same instance of contextValue each render until getSessionValue or setSessionValue changes.
  const contextValue = useMemo(() => {
    return {getSessionValue, setSessionValue}
  }, [getSessionValue, setSessionValue])

  // pass contextValue down to descendents via SessionStorageContext
  return (
    <SessionStorageContext.Provider value={contextValue}>
      {children}
    </SessionStorageContext.Provider>
  )
}

export const useSessionStorage = (key, defaultValue) => {
  // get value from nearest ancestor SessionStorageContext - provided by SessionStorageProvider
  const {getSessionValue, setSessionValue} = useContext(SessionStorageContext)

  const value = getSessionValue(key, defaultValue)
  const setValue = (value) => {
    setSessionValue(key, value)
  }

  return [value, setValue]
}
