import React, {useCallback, useEffect} from 'react'
import SearchForm from '../components/SearchForm'
import SearchHistory, {useSearchHistory} from '../components/SearchHistory'
import {useJobRepository} from '../respositories/JobRepository'
import {handleFetchError} from '../utils/api'
import heroSrc from 'assets/images/hero.jpg'
import heroXxlSrc from 'assets/images/hero-xxl.webp'
import heroXlSrc from 'assets/images/hero-xl.webp'
import heroLgSrc from 'assets/images/hero-lg.webp'
import heroMdSrc from 'assets/images/hero-md.webp'
import heroSmSrc from 'assets/images/hero-sm.webp'
import heroXsSrc from 'assets/images/hero-xs.webp'
import TeReo from '../components/TeReo'
import Meta from '../components/Meta'
import {useSearchParams} from '../utils/SearchParams'
import Link from '../components/Link'

const Search = () => {
  const {actions, error, loading} = useJobRepository()
  const [, pushSearchHistory] = useSearchHistory()
  const [, setSearchParams] = useSearchParams()

  // reset JobRepository data to {jobs: [], loading: false} when search page renders, to clear jobs & any error messages
  useEffect(() => {
    actions.clear()
  }, [actions])

  // useCallback will return the same instance of the search function unless navigate changes
  const search = useCallback(async (data) => {
    actions.index(data).catch(handleFetchError({except: 'INVALID'})).then(() => {
      pushSearchHistory(data)
      setSearchParams(data)
    }).catch(handleFetchError({only: 'INVALID'}))
  }, [actions, setSearchParams, pushSearchHistory])

  const clearErrors = useCallback(() => actions.clear('error'), [actions])

  return (
    <div>
      <Meta
        title="Find jobs in New Zealand"
        /* eslint-disable-next-line max-len */
        description="Work and Income can help you find a job. Search for full time and part time jobs around New Zealand."
      />

      <div className="bg-dark d-print-none">
        <picture>
          <source srcSet={heroXxlSrc} media="(min-width: 1400px)" type="image/webp" />
          <source srcSet={heroXlSrc} media="(min-width: 1200px)" type="image/webp" />
          <source srcSet={heroLgSrc} media="(min-width: 992px)" type="image/webp" />
          <source srcSet={heroMdSrc} media="(min-width: 768px)" type="image/webp" />
          <source srcSet={heroSmSrc} media="(min-width: 576px)" type="image/webp" />
          <source srcSet={heroXsSrc} type="image/webp" />
          <img src={heroSrc} alt="" className="hero-image" />
        </picture>
      </div>
      <div className="container-fluid">
        <div className="search-box">
          <h1 className="mb-2 ff-alt text-primary">
            <TeReo>E kimi mahi ana? <span className="d-none d-md-inline">Tīmata mai i konei</span></TeReo>
            <br />
            Looking For Work? <span className="d-none d-md-inline">Start Here</span>
          </h1>
          <SearchForm className="pt-4" errors={error?.meta} loading={loading} onSubmit={search}
                      onChange={clearErrors} />
        </div>
      </div>
      <SearchHistory className="search-history container-lg px-4 pt-5 pb-3" />
      <div className="container-lg px-4 px-lg-5 px-xl-4 py-5 my-md-4">
        <h2 className="text-dark fs-4 ff-base mb-2 mb-md-4">
          <TeReo>Kimi Wawe</TeReo> Quick Search
        </h2>
        <div className="row mb-3">
          <h3 className="text-dark col-2 fs-6 ff-base lh-lg m-0 d-none d-md-block">Cities</h3>
          <ul className="list-unstyled justify-content-start row col-12 col-md-10 fw-bold lh-lg p-0 m-0">
            <li className="col-auto"><Link to="/locations/auckland/jobs">Auckland</Link></li>
            <li className="col-auto"><Link to="/locations/wellington/wellington/jobs">Wellington</Link></li>
            <li className="col-auto"><Link to="/locations/canterbury/christchurch/jobs">Christchurch</Link></li>
            <li className="col-auto"><Link to="/locations/waikato/hamilton/jobs">Hamilton</Link></li>
            <li className="col-auto"><Link to="/locations/bay-of-plenty/tauranga/jobs">Tauranga</Link></li>
            <li className="col-auto"><Link to="/locations/otago/dunedin/jobs">Dunedin</Link></li>
          </ul>
        </div>
        <div className="row d-none d-md-flex">
          <h3 className="text-dark col-2 fs-6 ff-base lh-lg m-0">Regions</h3>
          <ul className="list-unstyled row col-10 fw-bold lh-lg p-0 m-0">
            <li className="col-auto"><Link to="/locations/northland/jobs">Northland</Link></li>
            <li className="col-auto"><Link to="/locations/bay-of-plenty/jobs">Bay of Plenty</Link></li>
            <li className="col-auto"><Link to="/locations/waikato/jobs">Waikato</Link></li>
            <li className="col-auto"><Link to="/locations/tairawhiti-gisborne/jobs">Tairāwhiti/Gisborne</Link></li>
            <li className="col-auto"><Link to="/locations/hawkes-bay/jobs">Hawke&apos;s Bay</Link></li>
            <li className="col-auto"><Link to="/locations/taranaki/jobs">Taranaki</Link></li>
            <li className="col-auto"><Link to="/locations/manawatu-whanganui/jobs">Manawatū/Whanganui</Link></li>
            <li className="col-auto"><Link to="/locations/wellington/jobs">Wellington/Wairarapa</Link></li>
            <li className="col-auto"><Link to="/locations/nelson-tasman/jobs">Nelson/Tasman</Link></li>
            <li className="col-auto"><Link to="/locations/marlborough/jobs">Marlborough</Link></li>
            <li className="col-auto"><Link to="/locations/west-coast/jobs">West Coast</Link></li>
            <li className="col-auto"><Link to="/locations/canterbury/jobs">Canterbury</Link></li>
            <li className="col-auto"><Link to="/locations/otago/jobs">Otago</Link></li>
            <li className="col-auto"><Link to="/locations/southland/jobs">Southland</Link></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Search
