import React from 'react'
import clsx from 'clsx'

const ResultsHelpText = (props) => {
  const {title, children, className, ...other} = props

  return (
    <div className={clsx(className, 'job-help-text')} {...other}>
      <h3 className="text-secondary">{title}</h3>
      {children}
    </div>
  )
}

export default ResultsHelpText

export const NoResultsHelpText = (props) => {
  const {className, ...other} = props

  return (
    <ResultsHelpText
      className={className}
      title="No jobs matched your search. Try one of these…"
      {...other}
    >
      <ul>
        <li>Check your spelling</li>
        <li>Broaden your selection of locations or categories</li>
      </ul>
    </ResultsHelpText>
  )
}

export const LimitedResultsHelpText = (props) => {
  const {jobCount, className, ...other} = props

  return (
    <ResultsHelpText
      className={className}
      title={`We’ve limited these results to ${jobCount} jobs. To see more jobs try…`}
      {...other}
    >
      <ul>
        <li>Searching for keywords</li>
        <li>Selecting a narrower set of locations or categories</li>
      </ul>
    </ResultsHelpText>
  )
}
