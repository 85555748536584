import React, {useEffect} from 'react'
import Link from '../../components/Link'
import Meta from '../../components/Meta'
import {trackNotFound} from '../../utils/tracking'

const NotFound = () => {
  useEffect(trackNotFound, [])

  return (
    <div className="container-lg max-width-xl pt-3 pb-5 px-4 px-lg-5">
      <Meta title="Page not found" status="404" />

      <h1 className="pt-3">Not Found</h1>
      <p>We couldn&apos;t find the page you are looking for. Try these tips:</p>
      <ul>
        <li>Try a <Link to="/">new search</Link></li>
        <li>Check the URL, there might be a typo.</li>
      </ul>
    </div>
  )
}

export default NotFound
