import React from 'react'
import clsx from 'clsx'

const DetailList = (props) => {
  const {children, className, ...other} = props

  return (
    <dl className={clsx(className, 'border-bottom border-secondary-light')} {...other}>
      {children}
    </dl>
  )
}

export default DetailList

export const Detail = (props) => {
  const {title, children, className, ...other} = props

  return (
    <div
      className={clsx(className, 'row g-0 py-2 py-md-3 border-top border-secondary-light')}
      {...other}
    >
      <dt className="col-12 col-md-6 col-lg-5 p-0 m-0 pe-md-5">{title}</dt>
      <dd className="col-12 col-md-6 col-lg-7 p-0 m-0">{children}</dd>
    </div>
  )
}
