import React, {useCallback} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {trackInternalLink} from '../utils/tracking'

const Link = (props) => {
  const {to, onClick, trackingName, ...other} = props

  const track = useCallback((event) => {
    onClick && onClick(event)
    trackInternalLink({name: trackingName || event.target.innerText, url: event.target.href})
  }, [onClick, trackingName])

  return (
    <RouterLink to={to} onClick={track} {...other} />
  )
}

export default Link
