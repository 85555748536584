import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

// ReactDOM.hydrate attaches the react tree to prerendered html. When we prerender we also add a script that adds the
// prerender data to window.appData so the data is available to the react tree once it mounts
ReactDOM.hydrate(
  <React.StrictMode>
    <App data={window.appData} />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
