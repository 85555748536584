import React from 'react'
import clsx from 'clsx'

const TeReo = (props) => {
  const {children, className, ...other} = props

  return (
    <span className={clsx('te-reo', className)} {...other} lang="mi">{children}</span>
  )
}

export default TeReo
