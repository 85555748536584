import React from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import {truncate} from 'lodash-es'

const JobSummary = (props) => {
  const {component: Component, title, id, date, children, className, ...other} = props

  return (
    <Component className={clsx(className, 'job-summary')} {...other} id={`job-${id}`}>
      <div className="row">
        <div className="col-12 col-lg flex-grow-1">
          <h2 className="fs-3 text-primary mb-1">{title}</h2>
          <div className="f-a11y-lg-bold ff-base fw-bold mb-1 lh-base text-secondary">Job ID {id}</div>
        </div>
        <div className="col flex-grow-0 text-nowrap fw-bold ff-base fs-6 lh-sm text-dark mb-1">
          Listed on {dayjs(date).format('D MMM YYYY')}
        </div>
      </div>

      {children}
    </Component>
  )
}

export default JobSummary

export const JobDetail = (props) => {
  const {title, children, ...other} = props
  const content = typeof children === 'string' ? truncate(children, {length: 400}) : children
  const Component = children ? 'h3' : 'div'

  return (
    <div {...other}>
      {title && (
        <Component className={clsx('fs-6 ff-base fw-bold lh-sm text-dark mb-1', children && 'pt-3')}>{title}</Component>
      )}
      {content && <p className="job-summary__detail_body">{content}</p>}
    </div>
  )
}
