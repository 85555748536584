import React, {useCallback} from 'react'
import {useSessionStorage} from '../utils/SessionStorage'
import Link from './Link'
import qs from 'qs'
import {useLocationRepository} from '../respositories/LocationRepository'
import {useJobCategoryRepository} from '../respositories/JobCategoryRepository'
import Loader from './Loader'
import {take, isEqualWith, omitBy} from 'lodash-es'
import {looseCustomizer} from '../utils/equal_customizers'
import TeReo from './TeReo'
import {ReactComponent as SearchIcon} from 'assets/images/search.svg'
import {isBlank} from '../utils/presence'
import {locationName} from '../utils/locations'
import {jobCategoryName} from '../utils/jobCategories'

const SearchHistory = (props) => {
  const [jobHistory] = useSearchHistory()
  const {locations} = useLocationRepository()
  const {jobCategories} = useJobCategoryRepository()
  const getLocationName = locationName(locations)
  const getCategoryName = jobCategoryName(jobCategories)

  return jobHistory?.length ? (
    <div {...props}>
      <h2 className="text-center text-dark mb-3">
        <TeReo className="d-inline-block">Ngā Kimitanga Inātata Nei</TeReo>
        {' '}
        <span className="d-inline-block">Recent Searches</span>
      </h2>
      <ul className="list-unstyled mb-0 gy-3 gx-4 row row-cols-1 row-cols-lg-3">
        {jobHistory.map(({locationId, keywords, categoryId}) => (
          <li className="col" key={`${locationId}-${keywords}-${categoryId}`}>
            <Link
              to={`/jobs?${qs.stringify({locationId, keywords, categoryId})}`}
              /* eslint-disable-next-line max-len */
              className="d-block text-dark text-decoration-none focus-indicator search-history-item"
              trackingName="Search History Item"
            >
              {locations.length ? (
                <div className="d-flex">
                  <div>
                    <SearchIcon className="me-2 align-baseline text-primary" />
                  </div>
                  <div className="text-truncate">
                    <div className="fw-bold text-primary text-truncate">
                      Jobs {' '}
                      {locationId ? `in ${getLocationName(locationId)}` : 'anywhere'}
                    </div>
                    <div className="text-truncate">
                      {keywords ? `“${keywords}”` : '–'}
                    </div>
                    <div className="fw-bold text-truncate">
                      {categoryId ? getCategoryName(categoryId) : 'All categories'}
                    </div>
                  </div>
                </div>
              ) : <div className="d-flex align-items-center" style={{height: '2.875rem'}}><Loader /></div>}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ) : null
}

export default SearchHistory

export const useSearchHistory = () => {
  const [searchHistory, setSearchHistory] = useSessionStorage('jobSearchHistory', [])

  const pushSearchHistory = useCallback(({locationId, keywords, categoryId}) => {
    const compactSearch = omitBy({locationId, keywords, categoryId}, isBlank)
    setSearchHistory(prev => (
      take([compactSearch, ...(prev || []).filter(item => !isEqualWith(compactSearch, item, looseCustomizer))], 3)
    ))
  }, [setSearchHistory])

  return [searchHistory, pushSearchHistory]
}
