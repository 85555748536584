import React, {useEffect} from 'react'
import Meta from '../../components/Meta'
import {trackLoadTooHigh} from '../../utils/tracking'
import heavyLoadImageUrl from 'assets/images/heavy-load.svg'
import TeReo from '../../components/TeReo'

const UnderMaintenance = () => {
  useEffect(trackLoadTooHigh, [])

  return (
    <div className="error-page">
      <Meta title="Heavy Load" />
      <div className="error-page__image">
        <img src={heavyLoadImageUrl} alt="" />
      </div>
      <div className="error-page__text-wrapper">
        <div className="error-page__text-container">
          <h1>We’re under heavy load</h1>
          <p>
            More people are trying to use <TeReo>Kimi Mahi Mai</TeReo> Find a Job right now than expected.
          </p>
          <p>
            Please try again later. We should have things under control soon.
          </p>
        </div>
      </div>
    </div>
  )
}

export default UnderMaintenance
