import React, {useCallback, useState} from 'react'
import ControlledForm, {ErrorMessage, Input} from './ControlledForm'
import clsx from 'clsx'
import MultiLevelSelect, {Option} from './MultiLevelSelect'
import {useLocationRepository} from '../respositories/LocationRepository'
import {useJobCategoryRepository} from '../respositories/JobCategoryRepository'
import TeReo from './TeReo'
import {useNavigate} from 'react-router-dom'
import Button from './Button'

const SearchForm = (props) => {
  const {onSubmit, onChange, data, className, loading, ...other} = props
  const {locations} = useLocationRepository()
  const {jobCategories} = useJobCategoryRepository()
  const navigate = useNavigate()
  const controlled = data !== undefined

  // if the data prop has been provided SearchForm will be controlled by it parent. It will render the provided data and
  // call the provided onChange handler when changes are made.
  // if the data prop has not been provided then it will handle it own state internally.
  // on the home page it controls its own state. On the search result page, the result page handles syncing the data
  // with query string so it need to control the search forms state.
  const [formState, setFormState] = useState(data || {})
  const formData = controlled ? data : formState

  // useCallback will return the same instance of the change function for the lifetime of the component
  const change = useCallback((newData) => {
    if (!controlled) {
      setFormState(prev => ({...prev, ...newData}))
    }
    onChange && onChange(prev => ({...prev, ...newData}))
  }, [controlled, onChange])

  // useCallback will return the same instance of the submit function unless searchHistory, setSearchHistory or formData
  // changes
  const submit = useCallback((event) => {
    event.preventDefault()
    if (/^\d{6,}$/.test(formData.keywords)) {
      navigate(`/jobs/${formData.keywords}`)
    } else {
      onSubmit && onSubmit(formData)
    }
  }, [onSubmit, formData, navigate])

  return (
    <ControlledForm className={clsx(className, 'search-form')}
                    onSubmit={submit} onChange={change} data={formData} {...other}>
      <div className="row">
        <div className="col-12 col-lg pb-3 min-input-width">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
              jsx-a11y/no-noninteractive-element-interactions */}
          <label className="form-label fs-5 text-primary fw-bold" id="location-label" htmlFor="location"
                 onClick={() => document.getElementById('location').focus()}>
            <TeReo>Tauwāhi</TeReo>
            <span aria-hidden="true"> | </span>
            Location
          </label>
          {/* Input passes value and onChange props to MultiLevel select so it can be controlled by Controlled Form */}
          <Input component={MultiLevelSelect} id="location" name="locationId" placeHolder="Choose..."
                 aria-labelledby="location-label">
            {locations.map(location => (
              <Option label={location.name} value={location.id} key={location.id}>
                {!!location.areas?.length && location.areas.map(area => (
                  <Option label={area.name} value={area.id} key={area.id} />
                ))}
              </Option>
            ))}
          </Input>
        </div>
        <div className="col-12 col-lg pb-3 min-input-width">
          <label className="form-label fs-5 text-primary fw-bold" htmlFor="keywords">
            <TeReo>Kupu Matua</TeReo>
            <span aria-hidden="true"> | </span>
            Keywords
          </label>
          <Input className="form-control" type="text" id="keywords" name="keywords" />
        </div>
        <div className="col-12 col-lg pb-3 min-input-width">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
              jsx-a11y/no-noninteractive-element-interactions */}
          <label className="form-label fs-5 text-primary fw-bold" id="category-label" htmlFor="category"
                 onClick={() => document.getElementById('category').focus()}>
            <TeReo>Te Momo</TeReo>
            <span aria-hidden="true"> | </span>
            Category
          </label>
          <Input component={MultiLevelSelect} id="category" name="categoryId" placeHolder="Choose..."
                 aria-labelledby="category-label">
            {jobCategories.map(category => (
              <Option label={category.name} value={category.id} key={category.id}>
                {!!category.subCategories?.length && category.subCategories.map(subCategory => (
                  <Option label={subCategory.name} value={subCategory.id} key={subCategory.id} />
                ))}
              </Option>
            ))}
          </Input>
        </div>
        <div className="col-12 col-xxl-auto text-center mx-auto search-btn-container">
          <Button
            rounded
            color="primary"
            submit
            disabled={loading}
            className={clsx('fs-5 fw-bold px-5 mt-1 mt-sm-4', loading && 'btn-loading')}
          >
            Search
          </Button>
        </div>
      </div>
      <ErrorMessage name="base" className="text-center pt-3" />
    </ControlledForm>
  )
}

export default SearchForm
