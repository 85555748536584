import React, {createContext, useContext, useEffect, useMemo, useState} from 'react'
import {show, index, useAbortable} from './apiActions'
import {get, handleFetchError} from '../utils/api'

const PAGE_SIZE = 30

const JobRepositoryContext = createContext({})

const JobRepository = (props) => {
  const {children, initialData: {data: initialJobs, ...initialData} = {}} = props
  // gets initial data from props so it can be set for server-side rendering (SSR)
  const [data, setData] = useState({loading: false, pageSize: PAGE_SIZE, ...initialData, jobs: initialJobs || []})
  const abortable = useAbortable()

  const actions = useMemo(() => ({
    show: abortable(show(setData, 'job_listings', 'jobs')),
    index: abortable(index(setData, 'job_listings', 'jobs', {limit: PAGE_SIZE})),
    showMore: ({all} = {}) => setData(prev => ({...prev, pageSize: all ? 500 : prev.pageSize + PAGE_SIZE})),
    clear: (property) => {
      setData(prev => (
        property
          ? {...prev, [property]: null}
          : {jobs: [], loading: false}
      ))
    },
  }), [abortable])

  // fetch remainder of jobs after initial page fetched by index action
  const {loading, meta, jobs, error, request} = data
  useEffect(() => {
    if (!loading && !error && meta?.count > PAGE_SIZE && jobs?.length <= PAGE_SIZE) {
      abortable((signal) => {
        get('job_listings', {...request, offset: jobs?.length}, signal).then(({data, meta}) => {
          setData(prev => ({...prev, jobs: [...prev.jobs, ...data], meta: {...meta, offset: 0}}))
        }).catch(handleFetchError)
      })()
    }
  }, [abortable, loading, meta, jobs, error, request])

  const repository = useMemo(() => ({
    ...data,
    actions,
  }), [data, actions])

  return (
    <JobRepositoryContext.Provider value={repository}>
      {children}
    </JobRepositoryContext.Provider>
  )
}

export default JobRepository

export const useJobRepository = () => {
  return useContext(JobRepositoryContext)
}
