import React, {useEffect, useRef} from 'react'
import Error from '../pages/error'

// This will call resolve to fetch it's dependency (on prop) if it is not present.
// If there is an error fetching the dependency it will display the appropriate error.
// If a loader has been provided it will display it while the dependency is being resolved
const RequiresService = (props) => {
  const {error, children} = props

  const errorType = error?.type === 'INVALID' ? undefined : error?.type

  const mountedRef = useRef(false)
  useEffect(() => {
    if (mountedRef.current) {
      const h1 = document.querySelector('h1')
      if (h1) {
        h1.tabIndex = -1
        h1.focus()
      }
    }
    mountedRef.current = true
  }, [errorType])

  return errorType ? <Error type={error?.type} /> : (children || null)
}

export default RequiresService
