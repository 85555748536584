import React, {useCallback} from 'react'
import Meta from '../../components/Meta'
import noInternetImageUrl from 'assets/images/no-internet.svg'
import Button from '../../components/Button'

const UnderMaintenance = () => {
  const refresh = useCallback(() => {
    // eslint-disable-next-line no-self-assign
    window.location = window.location
  }, [])

  return (
    <div className="error-page">
      <Meta title="No Internet" />
      <div className="error-page__image">
        <img src={noInternetImageUrl} alt="" />
      </div>
      <div className="error-page__text-wrapper">
        <div className="error-page__text-container">
          <h1>No Internet</h1>
          <p>
            The content for this page could not be downloaded. You’ve probably lost your connection to the internet.
          </p>
          <Button rounded color="primary" className="px-5" onClick={refresh}>Reload</Button>
        </div>
      </div>
    </div>
  )
}

export default UnderMaintenance
